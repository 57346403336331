import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import { Switch, Route, withRouter } from "react-router-dom";
import Loadable from "react-loadable";
import "../../node_modules/font-awesome/scss/font-awesome.scss";
import * as actions from "../store/index";
import Loader from "./layout/Loader";
import Aux from "../hoc/_Aux";
import ScrollToTop from "./layout/ScrollToTop";
import routes from "../route";
import $ from "jquery";

window.jQuery = $;
window.$ = $;
global.jQuery = $;
const AdminLayout = Loadable({
  loader: () => import("./layout/AdminLayout"),
  loading: Loader,
});

class App extends Component {
  authTimeout = 0;
  liveAuthTimeout = false;

  constructor(props) {
    super(props);
    if (this.props.isLoading) {
      this.props.onTryAutoSignup();
    }
  }

  componentDidUpdate() {}

  render() {
    const menu = routes.map((route, index) => {
      return route.component ? (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          name={route.name}
          onLeave={route.onLeave}
          render={(props) => <route.component {...props} />}
        />
      ) : null;
    });

    return (
      <Aux>
        {this.props.isLoading ? (
          <Loader />
        ) : (
          <ScrollToTop>
            <Suspense fallback={<Loader />}>
              <Switch>
                {this.props.isAuthenticated ? (
                  <Route path="/" component={AdminLayout} />
                ) : (
                  menu
                )}
              </Switch>
            </Suspense>
          </ScrollToTop>
        )}
      </Aux>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.sessionState.userId !== null,
    isLoading: state.sessionState.loading,
    loggedOut: state.sessionState.loggedOut,
    verificationEmailSent: state.sessionState.verificationEmailSent,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
