import { firebaseAuth, firebase } from "../../auth/Firebase/Firebase";
import * as moment from "moment";
import * as actionTypes from "./action-types";
import axios from "../../axios-spring";
import { TZ_OFFSET } from "../constant";

export const auth = (email, password, appVerifier) => {
  return (dispatch) => {
    dispatch(authStart());
    dispatch(setAuthLoadingSpinner(true));
    attemptLogin(email, password, appVerifier, dispatch);
  };
};

const attemptLogin = (email, password, appVerifier, dispatch) => {
  firebaseAuth
    .signInWithEmailAndPassword(email, password)
    .then(function (user) {
      setTimeout(() => {
        // This block is only reached if the user attempts to login for first time OR the user's MFA is DISABLED on firebase
        getUserByEmailThenValidateUser(email, user, appVerifier, dispatch);
      }, 1000);
    })
    .catch(function (error) {
      // This block is only reached if the user has MFA ENABLED on firebase or an error occurs
      handleLoginException(error, appVerifier, dispatch);
    });
};

const getUserByEmailThenValidateUser = (email, user, appVerifier, dispatch) => {
  axios
    .post("/appUsers/getByEmail/" + TZ_OFFSET, email, {
      headers: { "Content-Type": "text/plain" },
    })
    .then((response) => {
      const verificationDetails = {
        body: response.data.response,
        user: user,
        email: email,
        appVerifier: appVerifier,
        dispatch: dispatch,
      };
      validateUser(verificationDetails);
    })
    .catch((error) => {
      handleGetByEmailException(error, dispatch);
    });
};

const validateUser = (verificationDetails) => {
  const body = verificationDetails.body;
  const user = verificationDetails.user;
  const email = verificationDetails.email;
  const dispatch = verificationDetails.dispatch;

  const isEmailVerified = user?.user.emailVerified;
  const isMFAEnabled = user && body.mfaEnabled;

  if (!isExistingUser(body)) {
    dispatch(authFail("Your login details are incorrect."));
  } else if (!isEmailVerified) {
    const jsonBody = {
      continueUrl: process.env.REACT_APP_EMAIL_VERIFICATION_CONTINUEURL,
      email: email,
      firstName: body.firstName ? body.firstName : email,
    };
    sendVerificationEmail(jsonBody, dispatch);
  } else if (isMFAEnabled) {
    verifyUsersPhoneNumber(verificationDetails);
  } else {
    handleWhenUserLoggedIn(body, dispatch);
  }
};

const handleWhenUserLoggedIn = (body, dispatch) => {
  dispatch(refreshUserBatteryData(body.userId));
  dispatch(recordLogin(body));
  dispatch(setUserAuth(body));
};

const isExistingUser = (str) => str !== "User does not exist";

/** Sends verification email using spring boot API (**user must be authenticated to use this call)  */
const sendVerificationEmail = (jsonBody, dispatch) => {
  axios
    .post("/users/sendVerificationEmail", jsonBody)
    .then((response) => {
      dispatch(verificationEmailSent(true));
      dispatch(logout());
    })
    .catch((error) => {
      console.log(error);
    });
};

const verifyUsersPhoneNumber = (verificationDetails) => {
  const body = verificationDetails.body;
  const user = verificationDetails.user;
  const appVerifier = verificationDetails.appVerifier;
  const dispatch = verificationDetails.dispatch;

  user.user.multiFactor
    .getSession()
    .then((multiFactorSession) => {
      const phoneAuthProvider = new firebase.PhoneAuthProvider();
      const phoneInfoOptions = {
        phoneNumber: body.mobileNumber,
        session: multiFactorSession,
      };
      return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, appVerifier);
    })
    .then(function (verificationId) {
      dispatch(setVerificationId(verificationId));
      dispatch(setAuthLoadingSpinner(false));
    });
};

export const refreshUserBatteryData = (userId) => {
  return (dispatch) => {
    axios
      .get("/sonnenBatteryData/refreshBatteryData/" + userId)
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
  };
};

const handleGetByEmailException = (error, dispatch) => {
  const errorMessage = "There was an issue logging in. Please try again later.";
  dispatch(authFail(errorMessage));
  console.error(error);
};

const handleLoginException = (error, appVerifier, dispatch) => {
  const shouldSendMFACode = error.code === "auth/multi-factor-auth-required";
  const isLoginDetailsInvalid =
    error.code === "auth/wrong-password" ||
    error.code === "auth/invalid-email" ||
    error.code === "auth/user-not-found";
  if (shouldSendMFACode) {
    const resolver = error.resolver;
    setTimeout(() => {
      dispatch(phoneAuth(appVerifier, resolver));
    }, 2000);
  } else if (isLoginDetailsInvalid) {
    dispatch(authFail("Your login details are incorrect."));
  } else if (tooManyRequests(error)) {
    dispatch(authFail("You have made too many requests. Try again later."));
  } else {
    console.error(error);
  }
};

const tooManyRequests = (error) => error.code === "auth/too-many-requests";

export const phoneAuth = (appVerifier, resolver) => {
  return (dispatch) => {
    const phoneInfoOptions = {
      multiFactorHint: resolver.hints[0],
      session: resolver.session,
    };

    const phoneAuthProvider = new firebase.PhoneAuthProvider();

    phoneAuthProvider
      .verifyPhoneNumber(phoneInfoOptions, appVerifier)
      .then(function (verificationId) {
        dispatch(setVerificationId(verificationId, resolver));
        dispatch(setAuthLoadingSpinner(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(setAuthLoadingSpinner(false));
        if (tooManyRequests(error)) {
          dispatch(
            authFail("You have made too many requests. Try again later.")
          );
        }
      });
  };
};

// invoked when MFA code is submitted or password is changed
export const authFinal = (
  verificationCode,
  verificationId,
  resolver,
  method
) => {
  return (dispatch) => {
    dispatch(setAuthLoadingSpinner(true));
    const cred = firebase.PhoneAuthProvider.credential(
      verificationId,
      verificationCode
    );

    const hasResolver = resolver !== null && resolver !== undefined;
    const multiFactorAssertion =
      firebase.PhoneMultiFactorGenerator.assertion(cred);
    let firebaseUser = firebaseAuth.currentUser;

    if (hasResolver) {
      handleResolver(resolver, method, multiFactorAssertion, dispatch);
    } else {
      enrollUserWithMFA(firebaseUser, multiFactorAssertion, dispatch);
    }
    dispatch(setVerificationId(null, null));
  };
};

const handleResolver = (resolver, method, multiFactorAssertion, dispatch) => {
  resolver
    .resolveSignIn(multiFactorAssertion)
    .then(function (userCredential) {
      let user = userCredential.user;

      user.getIdToken().then((token) => {
        axios.defaults.headers.common = { Authorization: `${token}` };
        const isEmailVerified = user?.emailVerified;
        const hasChangedPassword = method === "Change Password";
        if (!isEmailVerified) {
          sendEmailVerificationUsingFirebase(user, dispatch);
        } else {
          dispatch(authLoading(true));
          axios
            .post("/appUsers/getByEmail/" + TZ_OFFSET, user.email, {
              headers: { "Content-Type": "text/plain" },
            })
            .then((response) => {
              const userBody = response.data.response;
              const deletedUser = userBody.isDeleted;
              if (hasChangedPassword) {
                handleUserWhoHasUpdatedPassword(userBody, dispatch);
                handleWhenUserLoggedIn(userBody, dispatch);
              } else if (deletedUser) {
                handleDeletedUser(dispatch);
              } else {
                handleWhenUserLoggedIn(userBody, dispatch);
              }
            })
            .catch((error) => {
              handleGetByEmailException(error, dispatch);
            });
        }
      });
    })
    .catch((error) => {
      handleFailedMFACheck(error, dispatch);
    });
};

const sendEmailVerificationUsingFirebase = (user, dispatch) => {
  user
    .sendEmailVerification()
    .then(function () {
      dispatch(verificationEmailSent(true));
      dispatch(setAuthLoadingSpinner(false));
      dispatch(logout());
    })
    .catch(function (error) {
      dispatch(setAuthLoadingSpinner(false));
      if (tooManyRequests(error)) {
        dispatch(authFail("You have made too many requests. Try again later."));
      }
    });
};

const handleUserWhoHasUpdatedPassword = (userBody, dispatch) => {
  axios
    .put("/users/updateLastResetDate/" + userBody.userId)
    .then((response) => {
      dispatch(setPasswordResetSuccess());
      dispatch(authLoading(false));
    });
};

const handleDeletedUser = (dispatch) => {
  dispatch(authFail("You are no longer registered to use this site."));
  dispatch(logout());
};

const handleFailedMFACheck = (error, dispatch) => {
  const invalidCode = error.code === "auth/invalid-verification-code";
  const missingCode = error.code === "auth/missing-verification-code";
  if (invalidCode) {
    dispatch(
      authFail("You entered the wrong verification code. Please try again.")
    );
    dispatch(setAuthLoadingSpinner(false));
  } else if (missingCode) {
    dispatch(authFail("Missing verification code."));
    dispatch(setAuthLoadingSpinner(false));
  }
};

// Invoked when a user verifies their phone number when they log in for the first time
const enrollUserWithMFA = (firebaseUser, multiFactorAssertion, dispatch) => {
  firebaseUser.multiFactor.enroll(multiFactorAssertion).then(function () {
    axios
      .post("/appUsers/getByEmail/" + TZ_OFFSET, firebaseUser.email, {
        headers: { "Content-Type": "text/plain" },
      })
      .then((response) => {
        let userBody = response.data.response;
        handleWhenUserLoggedIn(userBody, dispatch);
        dispatch(authLoading(false));
      })
      .catch((error) => {
        handleGetByEmailException(error, dispatch);
      });
  });
};

export const authCheckState = () => {
  return (dispatch) => {
    const checkAuthInterval =
      process.env.REACT_APP_USER_SESSION_CHECKAUTH_INTERVAL;

    firebaseAuth.onAuthStateChanged(function (user) {
      if (user) {
        user
          .getIdToken()
          .then((token) => {
            axios.defaults.headers.common = { Authorization: `${token}` };
            checkUserState(dispatch, user);
            if (window.authStateCheckInterval) {
              window.clearInterval(window.authStateCheckInterval);
              window.authStateCheckInterval = undefined;
            }
            window.authStateCheckInterval = setInterval(() => {
              checkUserState(dispatch, user);
            }, checkAuthInterval * 1000);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        dispatch(authLoading(false));
        dispatch(logout());
      }
    });
  };
};
function checkUserState(dispatch, user) {
  axios
    .post("/appUsers/getByEmail/" + TZ_OFFSET, user.email, {
      headers: { "Content-Type": "text/plain" },
    })
    .then((response) => {
      const userBody = response.data.response;
      const deletedUser = userBody.isDeleted;
      const shouldHaveMFAEnabledButDoesNot =
        userBody.mfaEnabled && user.multiFactor.enrolledFactors.length < 1;
      const isEmailVerified = user.emailVerified;

      if (!isExistingUser(userBody)) {
        dispatch(authFail("You are not registered to use this site."));
        dispatch(logout());
      } else if (deletedUser) {
        dispatch(authFail("You are no longer registered to use this site."));
        dispatch(logout());
      } else if (shouldHaveMFAEnabledButDoesNot) {
        dispatch(authLoading(false));
      } else if (isEmailVerified) {
        dispatch(setUserAuth(userBody));
        dispatch(setAuthLoadingSpinner(false));
      } else if (!isEmailVerified) {
        dispatch(
          authFail(
            "Your email is not verified. Please sign in again and a new verification link will be sent."
          )
        );
        dispatch(logout());
      }
    });
}

export const setUserAuth = (user) => {
  return (dispatch) => {
    // const expiresIn = process.env.REACT_APP_USER_SESSION_TIMEOUT;
    let numDaysLastReset = moment().diff(moment(user.lastResetDate), "days");
    const needsToResetPassword = !user.lastResetDate || numDaysLastReset > 90;

    if (needsToResetPassword) {
      dispatch(resetPassword(true, user.email));
    } else {
      setUsersAccess(user, dispatch);
    }
  };
};

const setUsersAccess = (user, dispatch) => {
  const expiresIn = process.env.REACT_APP_USER_SESSION_TIMEOUT;
  axios
    .get("/appRoles/getByUserId/" + user.userId)
    .then((response) => {
      let userRolesBody = response.data.response;
      return userRolesBody.map((x) => x.roleName);
    })
    .then((userRoles) => {
      dispatch(
        authSuccess(
          user.email,
          user.firstName,
          user.lastName,
          user.userId,
          user.awsEmailVerified,
          userRoles,
          user.parentOrganisationId
        )
      );
      dispatch(checkAuthTimeout(expiresIn));
    })
    .catch((error) => {
      console.error(error);
    });
};

export const checkAuthTimeout = (expirationTime) => {
  return (dispatch) => {
    if (!window.checkAuthTimeout) {
      window.checkAuthTimeout = setTimeout(() => {
        dispatch(logout());
      }, expirationTime * 1000);
    }
  };
};

export const verificationEmailSent = (verificationEmailSent) => {
  return {
    type: actionTypes.SET_VERIFICATION_EMAIL_STATUS,
    emailSent: verificationEmailSent,
  };
};

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (
  userEmail,
  userFirstName,
  userLastName,
  userId,
  awsEmailVerified,
  userRoles,
  parentOrganisationId
) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    userId: userId,
    userEmail: userEmail,
    userFirstName: userFirstName,
    userLastName: userLastName,
    awsEmailVerified: awsEmailVerified,
    userRoles: userRoles,
    parentOrganisationId: parentOrganisationId,
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

export const setUserEmail = (userEmail) => {
  return {
    type: actionTypes.SET_USER_EMAIL,
    userEmail: userEmail,
  };
};

export const setEmailVerifiedSuccess = (emailVerifiedSuccess) => {
  return {
    type: actionTypes.SET_EMAIL_VERIFIED,
    emailVerifiedSuccess: emailVerifiedSuccess,
  };
};

export const logout = () => {
  window.clearInterval(window.authStateCheckInterval);
  window.authStateCheckInterval = undefined;
  window.checkAuthTimeout = undefined;
  firebaseAuth.signOut().then(
    function () {},
    function (error) {
      console.log(error);
    }
  );
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const authLoading = (isLoading) => {
  return {
    type: actionTypes.AUTH_LOADING,
    loading: isLoading,
  };
};

export const setAuthLoadingSpinner = (isLoading) => {
  return {
    type: actionTypes.AUTH_LOADING_SPINNER,
    authLoading: isLoading,
  };
};

export const setAuthRedirectPath = (path) => {
  return {
    type: actionTypes.SET_AUTH_REDIRECT_PATH,
    path: path,
  };
};

export const forgotPasswordRequest = (email) => {
  let jsonBody = {
    email: email,
    continueUrl: process.env.REACT_APP_EMAIL_VERIFICATION_CONTINUEURL,
  };
  return (dispatch) => {
    axios
      .post("/users/sendResetPasswordEmail", jsonBody)
      .then(function (user) {
        dispatch(passwordResetEmailSent(true));
      })
      .catch(function (error) {
        dispatch(authFail(error));
      });
  };
};

export const passwordResetEmailSent = (passwordResetEmailSent) => {
  return {
    type: actionTypes.SET_PASSWORD_RESET_EMAIL_STATUS,
    emailSent: passwordResetEmailSent,
  };
};

export const updatePasswordFinal = (newPassword, email) => {
  return (dispatch) => {
    dispatch(setAuthLoadingSpinner(true));

    let firebaseUser = firebaseAuth.currentUser;

    firebaseUser
      .updatePassword(newPassword)
      .catch((error) => console.log(error));
    dispatch(authLoading(true));
    dispatch(passwordResetSuccess(email));
  };
};

export const passwordResetSuccess = (email) => {
  return (dispatch) => {
    axios
      .post("/appUsers/getUserIdByEmail", email, {
        headers: { "Content-Type": "text/plain" },
      })
      .then((response) => {
        const USER_ID = response.data.response;
        axios.put("/users/updateLastResetDate/" + USER_ID).then((response) => {
          dispatch(setPasswordResetSuccess());
          dispatch(authLoading(false));
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const setPasswordResetSuccess = () => {
  return {
    type: actionTypes.PASSWORD_RESET_SUCCESS,
    passwordResetSuccess: true,
    resetPassword: false,
  };
};

// invoked when user follows the reset password link and then submits the Change password form
export const resetPasswordRequest = (code, newPassword, email, appVerifier) => {
  return (dispatch) => {
    dispatch(setAuthLoadingSpinner(true));
    firebaseAuth
      .confirmPasswordReset(code, newPassword)
      .then(function () {
        firebaseAuth
          .signInWithEmailAndPassword(email, newPassword)
          .then(function (user) {
            user.getIdToken().then((token) => {
              axios.defaults.headers.common = { Authorization: `${token}` };
              dispatch(passwordResetSuccess(email));
            });
          })
          .catch(function (error) {
            handleLoginException(error, appVerifier, dispatch);
          });
      })
      .catch(function (error) {
        console.error(error);
        dispatch(authFail("There has been a problem resetting your password."));
      });
  };
};

// invoked when user changes initial password or password needs updated (expires after 90 days)
export const updatePassword = (
  email,
  oldPassword,
  newPassword,
  appVerifier
) => {
  return (dispatch) => {
    dispatch(setAuthLoadingSpinner(true));
    const cred = firebase.EmailAuthProvider.credential(email, oldPassword);
    firebaseAuth.currentUser
      .reauthenticateWithCredential(cred)
      .then(() => {
        firebaseAuth.currentUser
          .updatePassword(newPassword)
          .catch((error) => console.log(error));
        dispatch(authLoading(true));
        dispatch(passwordResetSuccess(email));
      })
      .catch((error) => {
        const wrongPassword = error.code === "auth/wrong-password";
        const shouldSendMFACode =
          error.code === "auth/multi-factor-auth-required";
        if (wrongPassword) {
          dispatch(authFail("Your old password was incorrect."));
        } else if (shouldSendMFACode) {
          let resolver = error.resolver;
          setTimeout(() => {
            dispatch(phoneAuth(appVerifier, resolver));
          }, 2000);
        } else {
          console.error(error);
          dispatch(authFail("There was a problem updating your password."));
        }
      });
  };
};

export const confirmPasswordResetLink = (code) => {
  return (dispatch) => {
    firebaseAuth
      .checkActionCode(code)
      .then(function (response) {
        dispatch(setUserEmail(response.data.email));
        dispatch(authLoading(false));
      })
      .catch(function (error) {
        dispatch(authLoading(false));

        dispatch(authFail("This link is no longer valid."));
      });
  };
};

export const verifyEmailRequest = (code) => {
  return (dispatch) => {
    firebaseAuth
      .applyActionCode(code)
      .then((response) => {
        dispatch(setEmailVerifiedSuccess(true));
      })
      .catch(function (error) {
        console.log(error);
        dispatch(authFail("This link is expired or invalid."));
      });
  };
};

export const verifyAwsEmailRequest = (email) => {
  return (dispatch) => {
    axios
      .post("/verifyAwsEmail", email, {
        headers: { "Content-Type": "text/plain" },
      })
      .then((response) => {
        dispatch(setEmailVerifiedSuccess(true));
      })
      .catch(function (error) {
        console.log(error);
        dispatch(authFail("There was a problem verifying your email."));
      });
  };
};

export const recordLogin = (user) => {
  return (dispatch) => {
    return axios.put("/users/updateLastLogin/" + user.userId);
  };
};

export const resetPassword = (boolean, email) => {
  return {
    type: actionTypes.RESET_PASSWORD,
    userEmail: email,
    resetPassword: boolean,
  };
};

export const setLoginInfo = (message) => {
  return {
    type: actionTypes.SET_LOGIN_INFO,
    error: message,
  };
};

export const clearLoginInfo = () => {
  return {
    type: actionTypes.CLEAR_LOGIN_INFO,
    error: null,
  };
};

export const clearError = () => {
  return {
    type: actionTypes.SET_CLEAR_ERROR,
    error: null,
  };
};

export const setVerificationId = (verificationId, resolver) => {
  return {
    type: actionTypes.SET_VERIFICATION_ID,
    verificationId: verificationId,
    resolver: resolver,
  };
};

export const setAwsEmailVerificationBoolean = (boolean) => {
  return {
    type: actionTypes.SET_AWS_VERIFICATION_BOOLEAN,
    awsVerificationBoolean: boolean,
  };
};

export const setAwsEmailVerified = (boolean) => {
  return {
    type: actionTypes.SET_AWS_EMAIL_VERIFIED,
    awsEmailVerified: boolean,
  };
};
