import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import App from "./App/index";
import Maintenance from "./Maintenance";
import * as serviceWorker from "./serviceWorker";
import rootReducer from "./store/index";
import config from "./config";

const maintenance_mode = process.env.REACT_APP_MAINTENANCE_MODE;

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

if (maintenance_mode === true || maintenance_mode === "true") {
  ReactDOM.render(<Maintenance />, document.getElementById("root"));
} else {
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter basename={config.basename}>
        <App />
      </BrowserRouter>
    </Provider>,
    document.getElementById("root")
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers:  http://bit.ly/CRA-PWA
serviceWorker.unregister();
