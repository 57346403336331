import * as moment from "moment";

const DEMO = {
  BLANK_LINK: "#!",
  HOME_LINK: "/",
  LOGOUT_LINK: "/logout",
  VERSION_LINK: "/version",
};

export default DEMO;

export const APP_NAME = process.env.REACT_APP_NAME;

//Timezone offset for API calls
export const TZ_OFFSET = Math.floor(new Date().getTimezoneOffset() / 60) * -1;

export const DATE_TIME_FORMAT = "DD-MM-YYYY HH:mm:ss";

export const DATE_FORMAT = "DD-MM-YYYY";

export const NA = "N/A";

export function isString(x) {
  return Object.prototype.toString.call(x) === "[object String]";
}

export function convertToSentenceCase(x) {
  let result = x.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export function dateFormatter(date) {
  if (date === "" || date === null) return NA;
  return moment(date).format(DATE_TIME_FORMAT);
}

