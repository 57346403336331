import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_SPRING_BOOT
    // baseURL: 'http://localhost:8081'
});


export default instance;

